<template>
    <div class="cart">
        <h3>Resource Condition</h3>
        <h6>资源条件</h6>
        <div class="box">
            <p>现有实验室建筑面积40000平方米和两条世界第二、亚太地区规模最大、国内唯一的大型地下爆炸试验巷道。</p>
            <p>先进的检测仪器和设备21000余台套，设备原值近3亿元。</p>
            <img width="593" src="~@/assets/res1.png">
            <div class="desc tc">图1   隔爆试验室</div>
            <img width="593" src="~@/assets/res2.png">
            <div class="desc tc">图2  本安试验室</div>
            <img width="593" src="~@/assets/res3.png">
            <div class="desc tc">图3  仪器仪表试验室</div>
            <img width="593" src="~@/assets/res4.png">
            <div class="desc tc">图4    通风机试验室</div>
            <img width="593" src="~@/assets/res5.png">
            <div class="desc tc">图5  高低压电器试验室</div>
            <img width="593" src="~@/assets/res6.png">
            <div class="desc tc">图6  大型实物模拟试验巷道</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'resCondition'
}
</script>
<style scoped lang="scss">
h6 {
    margin-bottom: 18px;
}
img{
    margin: 15px auto;
    display: inherit;
}
.box {
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333;
    line-height: 36px;

    .desc {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
    }
}
</style>